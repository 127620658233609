<template>
  <div class="dashboard-content statement-accounts" data-app>
    <!-- Content -->
    <div class="card">
      <div class="filters" style="padding: 10px 10px 0px 10px">
        <h3 class="text-center">
          {{ $t('Views.AdminStatementsAccount.title')
          }}<v-tooltip top v-if="admin === 1">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="#0c2c7c"
                dark
                class="mb-2"
                style="float: right"
                @click.prevent="handleCreate()"
                >{{ $t('Views.AdminStatementsAccount.createButton') }}
              </v-btn></template
            >
            <span>{{ $t('Views.AdminStatementsAccount.createText') }}</span>
          </v-tooltip>
        </h3>
      </div>
      <simple-list
        :dense="false"
        :headers="headers"
        :items="items"
        :totalItems="totalItems"
        :itemsPerPage="itemsPerPage"
        :loadingText="''"
        :hideFooter="true"
        :multiSort="false"
        :loading="loading"
        :class="'mb-2'"
        :sortBy="''"
        :page="page"
        :pages="pages"
        :showCreate="true"
        :showDelete="admin === 1 ? true : false"
        :orderOptions="orderOptions"
        :orderBy="orderBy"
        @create:item="handleCreate()"
        @edit:item="handleEdit($event)"
        @delete:item="deleteItem($event)"
        @update:page="pagination($event)"
        @orderEvent="handleOrder($event)"
      />
    </div>
    <modal name="crudModal" height="auto" :width="'80%'" :adaptive="true">
      <div class="card" style="padding: 2rem">
        <h3 class="text-center" v-if="flow == 'create'" style="margin-bottom: 20px">
          {{ $t('Views.AdminStatementsAccount.createText') }}
        </h3>
        <h3 class="text-center" v-if="flow == 'edit'" style="margin-bottom: 20px">
          {{ $t('Views.AdminStatementsAccount.editText') }}
        </h3>

        <v-form ref="form" v-model="valid" style="width: 100%">
          <v-row class="mx-0">
            <v-col cols="12" lg="4" md="6" sm="12" v-if="admin === 1">
              <v-row no-gutters>
                <span style="width: 30%"
                  >{{ $t('Views.AdminStatementsAccount.labelAssociation') }}:</span
                >
                <multiselect
                  style="width: 70%"
                  track-by="id"
                  label="name"
                  :placeholder="$t('Views.AdminStatementsAccount.placeholder')"
                  v-model="company"
                  :options="companies"
                  :multiple="false"
                  :close-on-select="true"
                  :clear-on-select="true"
                  :preserve-search="false"
                ></multiselect></v-row
            ></v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-select
                :readonly="admin === 0"
                :rules="[rules.required]"
                :items="statusOptions"
                :item-text="'name'"
                :item-value="'code'"
                v-model="status"
                :label="$t('Views.AdminStatementsAccount.labelStatus')"
                type="text"
              ></v-select
            ></v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-text-field
                :readonly="admin === 0"
                :counter="140"
                :rules="[rules.required, rules.max]"
                :label="$t('Views.AdminStatementsAccount.labelName')"
                v-model="name"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :counter="140"
                    :rules="[rules.required]"
                    :label="$t('Views.AdminStatementsAccount.labelDate')"
                    v-model="date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  :readonly="admin === 0"
                  v-model="date"
                  min="1990-01-01"
                  show-current
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" lg="6" md="6" sm="12">
              <v-row class="m-0">
                <a
                  v-if="flow == 'edit' && item.file"
                  target="_blank"
                  rel="noopener"
                  :href="item.file.route"
                  download
                  ><v-btn color="#aaa" outlined dark class="ma-2 white--text">
                    {{ $t('Views.AdminStatementsAccount.labelDownload') }}
                    <v-icon right> mdi-cloud-download </v-icon>
                  </v-btn></a
                >
                <v-file-input
                  v-if="admin === 1"
                  v-model="file"
                  chips
                  :rules="[rules.fileSize]"
                  ref="file"
                  show-size
                  accept=".pdf, .docx, .pptx, .xlsx"
                  :label="$t('Views.AdminStatementsAccount.labelFile')"
                ></v-file-input>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="mx-0">
            <v-btn
              :dark="valid"
              style="margin: auto"
              v-if="flow == 'create'"
              :disabled="!valid"
              color="#0c2c7c"
              @click="createItem()"
            >
              {{ $t('Views.AdminStatementsAccount.createButton') }}
            </v-btn>
            <v-btn
              :dark="valid"
              style="margin: auto"
              v-if="flow == 'edit'"
              :disabled="!valid"
              color="#0c2c7c"
              @click="saveItem()"
            >
              {{ $t('Views.AdminStatementsAccount.saveButton') }}
            </v-btn>

            <v-btn style="margin: auto" dark color="#0c2c7c" @click="reset">
              {{ $t('Views.AdminStatementsAccount.cleanButton') }}
            </v-btn>
            <v-btn
              dark
              style="margin: auto"
              color="#0c2c7c"
              @click="$modal.hide('crudModal')"
            >
              {{ $t('Views.AdminStatementsAccount.cancelButton') }}
            </v-btn>
          </v-row>
        </v-form>
      </div>
    </modal>
  </div>
</template>

<script>
  import SimpleList from 'Components/Listing/SimpleList.vue'
  import { toLocaleDateString } from '@/utils/helpers'

  export default {
    name: 'StatementsAccount',
    props: ['admin'],
    components: {
      SimpleList,
    },
    data() {
      return {
        config: {},
        loading: false,
        items: [],
        companies: [],
        company: '',
        page: 1,
        pages: 1,
        item: {},
        filterActivated: false,
        filters: {
          search: '',
        },
        totalItems: 0,
        itemsPerPage: 10,
        flow: '',
        activePicker: null,
        menu: false,
        file: null,
        name: '',
        status: 0,
        date: '',
        statusOptions: [
          { code: 1, name: 'Pendiente' },
          { code: 2, name: 'Solvente' },
        ],
        valid: true,
        rules: {
          required: (v) => !!v || 'Requerido.',
          min: (v) => v.length >= 8 || '8 caracteres como mínimo',
          max: (v) => v.length <= 140 || 'Debe poser menos de 140 caracteres',
          maxNumber: (v) => v.length <= 18 || 'Debe poser menos de 18 caracteres',
          email: (v) => (v ? /.+@.+\..+/.test(v) : true) || 'E-mail debe ser válido',
          fileSize: (v) =>
            (v ? v.size < 5120000 : true) ||
            'El peso del archivo debe ser inferior a 1MB',
        },
      }
    },
    computed: {
      headers() {
        return [
          {
            text: this.$t('Views.AdminStatementsAccount.headerAssociation'),
            value: 'company.name',
          },
          { text: this.$t('Views.AdminStatementsAccount.headerName'), value: 'name' },
          {
            text: this.$t('Views.AdminStatementsAccount.headerStatus'),
            value: 'statusText',
          },
          {
            text: this.$t('Views.AdminStatementsAccount.headerDate'),
            value: 'dateText',
            width: '200px',
          },
          { text: '', value: 'actions', align: 'center', width: '200px' },
        ]
      },
      orderOptions() {
        return [
          {
            id: 1,
            name: this.$t('Views.AdminStatementsAccount.orderOption1'),
            order: 'created_at',
            by: 'desc',
          },
          {
            id: 2,
            name: this.$t('Views.AdminStatementsAccount.orderOption2'),
            order: 'created_at',
            by: 'asc',
          },
          {
            id: 3,
            name: this.$t('Views.AdminStatementsAccount.orderOption3'),
            order: 'name',
            by: 'asc',
          },
          {
            id: 4,
            name: this.$t('Views.AdminStatementsAccount.orderOption4'),
            order: 'updated_at',
            by: 'desc',
          },
        ]
      },
      orderBy() {
        return {
          id: 1,
          name: this.$t('Views.AdminStatementsAccount.orderOption1'),
          order: 'created_at',
          by: 'desc',
        }
      },
    },
    created() {
      this.getCompanies()
      this.getStatementsAccount()
    },
    methods: {
      toLocaleDateString: toLocaleDateString,

      handleOrder(orderBy) {
        this.orderBy = orderBy
        this.getStatementsAccount()
      },

      async getCompanies() {
        axios.get('companies?type=1&limit=50').then((response) => {
          this.companies = response.data
        })
      },

      async getStatementsAccount() {
        this.loading = true
        let orderString = ''
        if (this.orderBy.order && this.orderBy.by) {
          orderString = '&order=' + this.orderBy.order + '&by=' + this.orderBy.by
        }
        axios
          .get('statement-accounts?page=' + this.page + orderString)
          .then((response) => {
            this.items = response.data.data
            if (this.items.length < 1) {
              this.loadingText = 'No hay registros'
            }
            this.totalItems = response.data.total
            this.itemsPerPage = response.data.per_page
            this.page = response.data.current_page
            this.pages = response.data.last_page
            this.items.map((x) => {
              switch (x.status) {
                case 1:
                  x.statusText = 'Pendiente'
                  break
                case 2:
                  x.statusText = 'Solvente'
                  break
                default:
                  x.statusText = ''
                  break
              }
              x.dateText = this.toLocaleDateString(x.date)
              x.file = x.uploads.find((upload) => upload.type === 'file')
            })
            this.loading = false
          })
      },

      async handleCreate() {
        this.flow = 'create'
        this.item = {}
        this.file = null
        this.company = ''
        this.name = ''
        this.status = 1
        this.date = new Date().toISOString().split('T')[0]
        this.$modal.show('crudModal')
      },

      async handleEdit(item) {
        this.flow = 'edit'
        this.item = item
        this.file = null
        this.company = this.item.company
        this.name = this.item.name
        this.status = this.item.status
        this.date = new Date(this.item.date + ' UTC').toISOString().split('T')[0]
        this.$modal.show('crudModal')
      },

      validate() {
        this.$refs.form.validate()
      },

      reset() {
        this.$refs.form.reset()
      },

      async createItem() {
        this.validate()
        let formData = new FormData()
        this.config.headers = {
          'Content-Type': 'multipart/form-data',
        }
        if (this.file) {
          formData.append('file', this.file)
        }
        if (this.company && this.company.id) {
          formData.append('company_id', this.company.id)
        }
        formData.append('fileType', 'file')
        formData.append('name', this.name)
        formData.append('status', this.status)
        formData.append('date', this.date)
        axios.post('statement-accounts', formData, this.config).then(() => {
          this.getStatementsAccount()
          this.$modal.hide('crudModal')
        })
      },

      async saveItem() {
        this.validate()
        let formData = new FormData()
        this.config.headers = {
          'Content-Type': 'multipart/form-data',
        }
        if (this.file) {
          formData.append('file', this.file)
        }
        if (this.company && this.company.id) {
          formData.append('company_id', this.company.id)
        }
        formData.append('fileType', 'file')
        formData.append('_method', 'patch')
        formData.append('name', this.name)
        formData.append('status', this.status)
        formData.append('date', this.date)
        axios
          .post('statement-accounts/' + this.item.id, formData, this.config)
          .then(() => {
            this.getStatementsAccount()
            this.$modal.hide('crudModal')
          })
      },

      async deleteItem(item) {
        axios.delete('statement-accounts/' + item.id).then((response) => {
          this.items = []
          this.getStatementsAccount()
        })
      },

      async pagination(page) {
        this.page = page
        this.items = []
        await this.getStatementsAccount()
      },
    },
  }
</script>
<style lang="scss">
  @import '@/assets/scss/cruds/_crudStyles.scss';
  .v-picker__title {
    background-color: #0c2c7c !important;
  }
  .v-date-picker-table .v-btn.v-btn--active {
    color: #000000a1 !important;
  }
</style>
